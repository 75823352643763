import QRScanner from "./QRScanner";

const QR = () => {
  return (
    <div>
      <QRScanner />
    </div>
  );
};
export default QR;
